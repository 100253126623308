$( document ).ready(function() {
		let recaptcha_validate = 0;
		let ExFormValid = "";
		let form_error_mssg = "";
		let form_validation = 0;
		let form_Input_value = "";
		let validation_array = [];
		let form_uniq_val = [];
		let button_data = "";
		let previous_option_1 = "clicked";
		let previous_option_2 = "clicked";
		let form_step_1  = 0;
		$.ajaxSetup({
	  		headers: {
	    		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	  		}
		});	


		function isName(name) {
	      var regex = /^[a-zA-Z][a-zA-Z\s]*$/;
	      return regex.test(name);
	    }
	    function isPhone(phone) {
	      var regex = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
	      return regex.test(phone);
	    }
	    function isEmail(email) {
	      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	      return regex.test(email);
	    }

	    function nullValueCheck(value,id) {
	      setTimeout(function () {  
	        if(value=='validation-recapcha'){
	          error_mssg = 'Verification failed, Please try again ';
	          grecaptcha.reset();
	          $("#g-recaptcha-response").trigger("reset");  
	        }
	        else{
	          error_mssg = 'This Field is required';
	        }
	        $('#'+id+'-'+value+'-custom-error').text(error_mssg); 
	        $('#'+id+'-'+value+'-custom-error').show();
	      },200)
	    }

		function form_validation_func(clickEvent,form_Input_value,IDname,Error_Mssg,Ex_Type) {
		    if(Ex_Type=="name"){
		      ExFormValid = isName(form_Input_value);
		      	if(clickEvent=='onchange_button'){
		      		form_error_mssg = "Please enter a valid name.";
		  		}
		    }
		    else if(Ex_Type=="email"){
		      	ExFormValid = isEmail(form_Input_value);
		      	if(clickEvent=='onchange_button'){
		      		form_error_mssg = "Please enter a valid email address.";
		  		}
		    }
		    else if(Ex_Type=="phone"){
		      	inputtype = document.getElementById(IDname);
		      	if (inputtype.value.length > 10) {
		          inputtype.value = inputtype.value.slice(0,10); 
		      	} 
				ExFormValid = isPhone(form_Input_value);
				if(clickEvent=='onchange_button'){
		      		form_error_mssg = "Please enter a valid mobile number.";
		  		}
				
		    }
		    else if(Ex_Type=="reaccount"){
		        if($(`#${IDname}`).val()==($('#form_bank_account').val())){
		          ExFormValid=true;
		        }
		        else{
		          ExFormValid=false;
		        } 
		    }
		    else{
		      ExFormValid=true;
		    }

		    if(form_Input_value){  
		      	if(ExFormValid==false){ 
			      	if(clickEvent=='onchange_button'){
			      		setTimeout(function () {  
			          		$(`#${IDname}`).addClass('require_text_border');
			            	$(`#${Error_Mssg}`).text(form_error_mssg); 
			            	$(`#${Error_Mssg}`).show();
			          	},200)
			      		form_validation = 0;
			          	form_step_1 = 0;
			      	}      
		      	}
		      	else{
		        	setTimeout(function () { 
		          		$(`#${IDname}`).removeClass('require_text_border');  
		              	$(`#${Error_Mssg}`).hide(); 
		          	},200)
		        	form_validation = 1;
		          	form_step_1 = 1; 
		      	}                   
		  	}
		    else{
		        setTimeout(function () {
		          $(`#${IDname}`).addClass('require_text_border');
		            $(`#${Error_Mssg}`).text('This Field required'); 
		            $(`#${Error_Mssg}`).show();  
		        },10)
		      	form_validation = 0;
		        form_step_1 = 0;
		  	}     
		}


		function loop_check(form_form_data){
		  for (let x in form_form_data) {
		    if(form_form_data[x].type=="text"){
		      	InputAcvalue = $(`#${form_form_data[x].id}`).val();
		    }
		    else if(form_form_data[x].type=="radio"){
		      	InputAcvalue =  $(`input[type=radio][name=${form_form_data[x].id}]:checked`).val();
		    }
		    else if(form_form_data[x].type=="checkbox"){
		      	InputAcvalue =  $(`input[type=checkbox][name=${form_form_data[x].id}]:checked`).val();
		    }
		    else if(form_form_data[x].id=="form_bank_name"){
		      
		    }
		    else{
		      InputAcvalue = "";
		    } 
		    
		      form_validation_func('submit_button',InputAcvalue,form_form_data[x].id,form_form_data[x].error,form_form_data[x].ex_type);		      
		      validation_array.push(form_step_1); 
		  }

		}

		function onChange_loop_check(form_form_data){
		  for (let x in form_form_data) {
		  	$(`#${form_form_data[x].id}`).on('input', function() {
			    if(form_form_data[x].type=="text"){
			      	InputAcvalue = $(`#${form_form_data[x].id}`).val();
			    }
			    else if(form_form_data[x].type=="radio"){
			      	InputAcvalue =  $(`input[type=radio][name=${form_form_data[x].id}]:checked`).val();
			    }
			    else if(form_form_data[x].type=="checkbox"){
			      	InputAcvalue =  $(`input[type=checkbox][name=${form_form_data[x].id}]:checked`).val();
			    }
			    else if(form_form_data[x].id=="form_bank_name"){
			      // alert(InputAcvalue);
			    }
			    else{
			      InputAcvalue = "";
			    } 
		      form_validation_func('onchange_button',InputAcvalue,form_form_data[x].id,form_form_data[x].error,form_form_data[x].ex_type);
		      // validation_array.push(form_step_1); 
		    });
		  }
		}

	// Contact
	   	let contact_form_validation = [
	    	{
		        "id": "contact_name",
		        "type": 'text',
		        "error" : "contact_name_custom_error",
		        "ex_type" : "name",
	    	},
	    	{
		      	"id": "contact_email",
		        "type": 'text',
		        "error" : "contact_email_custom_error",
		        "ex_type" : "email",
	    	},
	    	{ 
		        "id": "contact_mobile",
		        "type": 'text',
		        "error" : "contact_mobile_custom_error",
		        "ex_type" : "phone",
	      	},
	      	{
		        "id": "contact_type",
		        "type": 'text',
		        "error" : "contact_type_custom_error",
		        
	      	},
	      	{
		        "id": "contact_message",
		        "type": 'text',
		        "error" : "contact_message_custom_error",
	      	},
	  	]

		// OnKEYUP
		onChange_loop_check(contact_form_validation);
		// OnSubmit
	    $('#contact-lead-form').submit(function(e) {
	   		e.preventDefault();
	   		let submitRecaptchaInput = $('#g-recaptcha-response').val(); 
	    	let formData = new FormData(this);
	    	loop_check(contact_form_validation);
	    	console.log(validation_array);

		    form_uniq_val = [...new Set(validation_array)];
		    form_uniq_val.sort().reverse();
		    validation_array = [];
		    // Contact Recaptcha validation
	          	if(submitRecaptchaInput){   
	            	setTimeout(function () {   
	                	$('#contact-recaptcha-custom-error').hide(); 
	              	},200)
	            	recaptcha_validate = 1;                  
	          	}
	          	else{
	            	setTimeout(function () {
	                	$('#contact-recaptcha-custom-error').text('This Field is required'); 
	                	$('#contact-recaptcha-custom-error').show();  
	            	},10)
	            	recaptcha_validate = 0;
	          	}

		    // recaptcha_validate = 1;
	        if(form_uniq_val[1]==""){}
	      	else{
	        	if(form_uniq_val[0]==1){
			    	if(form_step_1==1 && recaptcha_validate==1 ){
			    		// alert('working');
			      		$.ajax({
				            dataType: 'JSON',
				            type:'POST',
				            url: 'https://4amworldwide.com/php/ajax.php?form=contact_lead',
				            data: formData,
				            cache:false,
				            contentType: false,
				            processData: false,
							beforeSend: function (request) {
								$("#aipr-register-success-mssg").html('Please Wait . . . <sapn class="spinner-border" role="status" style=" "> <span class="visually-hidden">Loading...</span> </sapn>');
						    },
				            success: function(response){
								swal({
									title: "Thank you for contacting us.",
									text: "We will get back to you shortly.",
									icon: "success",
									button: "OK",
								  });
								  
    							// Add further actions here.
							},
				        });
		    		}
		    	}
	  		}		
	  	});
	// Contact End Here


	// Career
	    let career_form_validation = [
	    	{
		        "id": "career_name",
		        "type": 'text',
		        "error" : "career_name_custom_error",
		        "ex_type" : "name",
	    	},
	    	{
		      	"id": "career_email",
		        "type": 'text',
		        "error" : "career_email_custom_error",
		        "ex_type" : "email",
	    	},
	    	{ 
		        "id": "career_mobile",
		        "type": 'text',
		        "error" : "career_mobile_custom_error",
		        "ex_type" : "phone",
	      	},
	      	{
		        "id": "career_location",
		        "type": 'text',
		        "error" : "career_location_custom_error",
		        
	      	},
	  	]

		// OnKEYUP
		onChange_loop_check(career_form_validation);
		// OnKEYUP Career Designation validation
		$('#career_designation').on("input", function () {
			var careerDesignLabelInput = $("#career_designation").val();
			if($('#career_designation').length && $('#internship-dropdown').attr('data-check')=="something"){
				if (careerDesignLabelInput) {
					setTimeout(function () {
						$("#career_designation_custom_error").hide();
					}, 200);
					turnover_validate = 1;
				} else {
					setTimeout(function () {
						$("#career_designation_custom_error").text( "This field is required" );
						$("#career_designation_custom_error").show();
					}, 10);
					turnover_validate = 0;
				}
			}
			else{
				setTimeout(function () {   
					$('#career_designation_custom_error').hide(); 
				},200)
				recaptcha_validate = 1;
			}

		});

		// File Upload
        $('#career_resume').on('input', function() {
	        var inputElement = document.getElementById('career_resume');
	        var files = inputElement.files;
	        if(files.length==1){
	           	var filename = this.files[0].name;
	            var extension = filename.substr(filename.lastIndexOf("."));
	            var allowedExtensionsRegx = /(\.pdf|\.doc|\.docx)$/i;
	            var isAllowed = allowedExtensionsRegx.test(extension);
	            var filesize = this.files[0].size / 1024 / 1024;
	            if (filesize<='2'){
	              if(isAllowed){
	                setTimeout(function () {  
	                	$('#career_resume_custom_error').hide(); 
	                },200)
	                file_validate = 1; 
	              }
	              else{
	                setTimeout(function () {
	                    $('#career_resume_custom_error').text('Only PDF or Doc files are Allowed'); 
	                    $('#career_resume_custom_error').show();  
	                },10)
	                file_validate = 0;
	              }
	            }
	            else{
	              setTimeout(function () {
	                  $('#career_resume_custom_error').text('File should be less than 2 MB'); 
	                    $('#career_resume_custom_error').show();  
	              },10)
	              file_validate = 0; 
	            }
	          }
	          else{
	               setTimeout(function () {
	                    $('#career_resume_custom_error').text('This Field is required'); 
	                    $('#career_resume_custom_error').show();  
	              },10)
	              file_validate = 0;
	            }               
	        }); 

        // OnSubmit
	    $('#career-lead-form').submit(function(e) {
	   		e.preventDefault();
	   		let submitFileInput = $('#career_resume').val();
	   		let submitRecaptchaInput = $('#g-recaptcha-response').val(); 
	    	let formData = new FormData(this);
	    	loop_check(career_form_validation);
	    	console.log(validation_array);
		    form_uniq_val = [...new Set(validation_array)];
		    form_uniq_val.sort().reverse();
		    validation_array = [];
			// Career Designation validation
				if ($('#career_designation').length && $('#internship-dropdown').attr('data-check')=="something" ){
					let careerDesignLabelInput = $('#career_designation').val();
					if(careerDesignLabelInput){   
						setTimeout(function () {   
							$('#career_designation_custom_error').hide(); 
						},200)
						recaptcha_validate = 1;                  
					}
					else{
						setTimeout(function () {
							$('#career_designation_custom_error').text('This Field is required'); 
							$('#career_designation_custom_error').show();  
						},10)
						recaptcha_validate = 0;
					}
				}
				else{
					setTimeout(function () {   
						$('#career_designation_custom_error').hide(); 
					},200)
					recaptcha_validate = 1;
				}
		    // Career Resume validation
              	if(submitFileInput){
                  	var inputElement = document.getElementById('career_resume');
                  	var files = inputElement.files;
                  	var filename = inputElement.files[0].name;
                  	var extension = filename.substr(filename.lastIndexOf("."));
                  	var allowedExtensionsRegx = /(\.pdf|\.doc|\.docx)$/i;
                  	var isAllowed = allowedExtensionsRegx.test(extension);
                  	var filesize = inputElement.files[0].size / 1024 / 1024;
                  	if (filesize<='2'){
                    	if(isAllowed){
                        	setTimeout(function () {   
                            	$('#career_resume_custom_error').hide(); 
                        	},200)
                        	file_validate = 1; 
                      	}
                      	else{
                        	setTimeout(function () {
                            	$('#career_resume_custom_error').text('Only PDF or Doc files are Allowed'); 
                            	$('#career_resume_custom_error').show();  
                        	},10)
                        	file_validate = 0;
                      	}
                  	}
                  	else{
                      	setTimeout(function () {
                        	$('#career_resume_custom_error').text('File should be less than 2 MB'); 
                          	$('#career_resume_custom_error').show();  
                      	},10)
                      	file_validate = 0; 
                  	}
              	}
              	else{
                  	setTimeout(function () {
                      	$('#career_resume_custom_error').text('This Field is required'); 
                      	$('#career_resume_custom_error').show();  
                  	},10)
                  	file_validate = 0;
              	}
		    // Contact Recaptcha validation
	          	if(submitRecaptchaInput){   
	            	setTimeout(function () {   
	                	$('#career-recaptcha-custom-error').hide(); 
	              	},200)
	            	recaptcha_validate = 1;                  
	          	}
	          	else{
	            	setTimeout(function () {
	                	$('#career-recaptcha-custom-error').text('This Field is required'); 
	                	$('#career-recaptcha-custom-error').show();  
	            	},10)
	            	recaptcha_validate = 0;
	          	}


		    // recaptcha_validate = 1;
	        if(form_uniq_val[1]==""){}
	      	else{
	        	if(form_uniq_val[0]==1){
			    	if(form_step_1==1 && recaptcha_validate==1 && file_validate==1){
			      		$.ajax({
				            dataType: 'JSON',
				            type:'POST',
				            url: 'http://localhost:8080/projects/4am_cms/html/php/ajax.php?form=career_lead',
				            data: formData,
				            cache:false,
				            contentType: false,
				            processData: false,
				            beforeSend: function (request) {
				              	$('.loader_').addClass('show');
				            },
				            success: function(response){
				              	//response =  JSON.parse(response);
				              	if(response.status==true){
				              			$('.loader_').removeClass('show');
				              			Swal.fire({
										  icon: 'success',
										  title: 'Successfully',
										  text: 'Your Application has been sent',
										}).then(function() {
										   location.reload();
										});
				            	}
				            	else if(response.status==false){
				                	if(response.error){
				                  		nullValueCheck(response.error,'career');
				                	}
				                	else{}
				            	}
				            	else{}  
				          	},
				        });
		    		}
		    	}
	  		}		
	  	});
});