var isMobile = new Object();
isMobile.Android = function () {
	return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function () {
	return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function () {
	return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

isMobile.iPad = function () {
	return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function () {
	return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function () {
	return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function () {
	return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function () {
	return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function () {
	return (
		isMobile.Android() ||
		isMobile.BlackBerry() ||
		isMobile.iOS() ||
		isMobile.Opera() ||
		isMobile.Windows()
	);
};
if (isMobile.any() && isMobile.iPad() == null) {
	//return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();

var disableScrolling = function () {
	var x = window.scrollX;
	var y = window.scrollY;
	window.onscroll = function () { window.scrollTo(x, y); };
}
var enableScrolling = function () {
	window.onscroll = function () { };
}
window.onbeforeunload = function () {
	//window.scrollTo(0, 0);
}

$(window).on('load', function () {
	window.scrollTo(0, 0);
});

var winWT = $(window).width();
$(window).on('load', function () {
	// AOS On Scroll Reveal
	if ((winWT >= 800)) {
		$(function () {
			AOS.init({
				easing: 'ease-out-in',
				duration: 1200,
				disable: function () {
					var maxWidth = 767;
					return window.innerWidth < maxWidth;
				}
			});
		});
	} else {
		$(function () {
			AOS.init({
				disable: function () {
					var maxWidth = 800;
					return window.innerWidth < maxWidth;
				}
			});
		});
	};
});

$(document).ready(() => {
	//console.log('Ganpati bappa morya!!!');
	$(".slimmenu").slimmenu({
		resizeWidth: '1200',
		collapserTitle: '',
		animSpeed: '500',
		easingEffect: null,
		indentChildren: false,
		childrenIndenter: '&nbsp;'
	});

	/**************************************************************/
	/**************************************************************/

	var currScroll;
	var currentScrollTop = 0;
	var header = $('header');
	var menuLinks = $('.nav-item');
	$(window).scroll(function () {
		setHeaderOnScrollDesktop();
	});
	$(window).resize(function () {
		setHeaderOnScrollDesktop();
		setRotateMsgForMobile();
	});

	function setRotateMsgForMobile(){
		winWT = $(window).innerWidth();
		console.log(winWT);
		if (winWT < 1024) {
			// Define a function to handle orientation changes
			function handleOrientationChange(event) {
				const isLandscape = event.matches;
				$(".rotate-msg").css("display", isLandscape ? "block" : "none");
			}
			// Create a media query for landscape orientation
			const landscapeMediaQuery = window.matchMedia("(orientation: landscape)");
		
			// Listen for changes in orientation using the media query
			landscapeMediaQuery.addListener(handleOrientationChange);
		
			// Initial check
			handleOrientationChange(landscapeMediaQuery);
		}
	}
	setRotateMsgForMobile();

	function setHeaderOnScrollDesktop() {
		winWT = $(window).width();
		if (winWT > 1023) {
			var scrollTop = $(window).scrollTop();
			var headerHT = header.innerHeight();
			currentScrollTop = scrollTop;
			if (currScroll < currentScrollTop && scrollTop > headerHT) {
				header.removeClass("sticky");
				header.css({ transform: 'translateY(-' + headerHT + 'px)' });
			} else if (currScroll > currentScrollTop && !(scrollTop <= headerHT)) {
				header.addClass("sticky");
				header.css({ transform: 'translateY(0)' });
			} else if (currScroll > currentScrollTop && !(scrollTop == 0)) {
				header.removeClass("sticky");
				header.css({ transform: 'translateY(0)' });
			}
			currScroll = currentScrollTop;
		} else {
			var scrollPosition = $(this).scrollTop();
			var threshold = 100;
			if (scrollPosition > threshold) {
				$('header').addClass('sticky');
			} else {
				$('header').removeClass('sticky');
			}
		}
	}

	$('#nav-icon1').click(function () {
		if ($('#nav-icon1').hasClass('open')) {
			$(this).removeClass('open');
			closeMobileMenu();
		} else {
			$(this).addClass('open');
			TweenMax.to('.navbar-collapse', 0.3, { right: 0, ease: Sine.easeInOut });
			TweenMax.set('body', { 'overflow': 'hidden' }, 'lable');
		}
	});

	function closeMenuOnLinkClick() {
		
		winWT = $(window).width();
		
		if (winWT < 1024) {
			console.log(winWT);
			$('.nav-link').on('click', function () {
				
				$('#nav-icon1').removeClass('open');
				closeMobileMenu();
			});
		}
	}

	closeMenuOnLinkClick();

	function closeMobileMenu() {
		console.log('sdfdsf');
		TweenMax.to('.navbar-collapse', 0.3, { right: '-100%', ease: Sine.easeInOut }, 'lable');
		TweenMax.set('body', { 'overflow': 'auto' });
	}

	$('.nav-link').on('click',function(){
		$('html, body').animate({
			scrollTop: $( $(this).attr('href') ).offset().top - 20
		}, 500);
		
		return false;
	});


	/*=======================================================================*/
	var player = null;
	window.playYoutubeVideo = function(){
		if(player != null){
			player.playVideo();
		}
	}
	window.pauseYoutubeVideo = function(){
		if(player != null){
			player.pauseVideo();
		}
	}
	window.stopYoutubeVideo = function () {
		if(player != null){
			player.stopVideo();
		}
	}
	window.muteYoutubeVideo = function () {
		if(player != null){
			player.mute();
		}
	}
	window.unMuteYoutubeVideo = function () {
		if(player != null){
			player.unMute();
		}
	}
	setupYoutubePlayer = function (){
		var tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		
		// 3. This function creates an <iframe> (and YouTube player)
		//    after the API code downloads.
		
		
		window.onYouTubeIframeAPIReady = function () {
			player = window.player = new YT.Player('player', {
			height: '390',
			width: '640',
			videoId: 'WE4X0FhoKZ4',
			playerVars:{
				showinfo:0,
				rel:0,
				controls:0,
				loop:1,
				mute:1,
				playerVars: { 
				  autoplay: 1, 
				  playsinline: 1, 
				  rel:0,
				  modestbranding:1,
				  autohide:1,
				  showinfo:0,
				},
				/* playlist:'iM5aQoAsVl4', */
				modestbranding:1,
				autohide:1,
				showinfo:0,
				color:"#ffff00"
			},
			events: {
				'onReady': onPlayerReady,
				'onStateChange': onPlayerStateChange
			}
			});
		}
		// 4. The API will call this function when the video player is ready.
		function onPlayerReady(event) {
		  //console.log("ready", player)
  
			//event.target.playVideo();
			//window.playYoutubeVideo();
		}
		// 5. The API calls this function when the player's state changes.
		//    The function indicates that when playing a video (state=1),
		//    the player should play for six seconds and then stop.
		var done = false;
		function onPlayerStateChange(event) {
			if (event.data == YT.PlayerState.PLAYING && !done) {
			/*setTimeout(stopVideo, 6000);
			done = true;*/
			}
		}
	}
	$(window).bind('load', function(){
		setupYoutubePlayer(); 
	});
});